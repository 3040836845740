import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { getOrderFilterOptions, getOrderFilterPlaceHolder } from '@constants';
import {
	DiscountBanner,
	EmptyContainer,
	Flex,
	Heading,
	HistoryItem,
	HistorySkeletonLoader,
	Pagination,
	Select,
	SelectOption,
	skeletonStyles,
} from '@components';
import { OrderDataPOMS } from '@ts/poms/orders';
import { useTranslation, useWindowDimensions } from '@utils/hooks';

type HistoryListProps = {
	containerClass: string;
	handleItemClick: (id: string, el?: EventTarget & HTMLDivElement) => void;
	handlePagination: (args: number) => void;
	handleSelect: (selection: SelectOption) => void;
	isLoading: boolean;
	orderData: OrderDataPOMS;
	pagesLength: number;
};

const getTranslatedTexts = translator => {
	return {
		noOrdersInTimePeriod: translator('no-orders-in-time-period'),
		noOrdersFound: translator('no-orders-found'),
		ordersPlaced: translator('orders-placed'),
		orderPlaced: translator('order-placed'),
	};
};

const HistoryList = ({
	containerClass,
	handleItemClick,
	handlePagination,
	handleSelect,
	isLoading,
	orderData,
	pagesLength,
}: HistoryListProps) => {
	const { width } = useWindowDimensions();
	const hasAccountData = !!orderData?.orders.length;
	const isDesktopView = width > 768;
	const isBfroDiscount = useFeatureIsOn('is-bfro-discount');
	let historyList = null;
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);

	if (isLoading) historyList = <HistorySkeletonLoader />;
	else {
		if (hasAccountData) {
			orderData.orders.length
				? (historyList = orderData?.orders.map((order, index) => (
						<HistoryItem key={`order-${index}`} order={order} handleItemClick={handleItemClick} />
					)))
				: (historyList = <EmptyContainer text={translations.noOrdersInTimePeriod} />);
		} else historyList = <EmptyContainer text={translations.noOrdersFound} />;
	}

	return (
		<>
			<Flex column fullWidth fullHeight justify='start' align='center' gap={4} className={containerClass}>
				<Flex fullWidth align='center' justify={isDesktopView ? 'start' : 'between'}>
					<Heading tag='h4' style={{ paddingRight: '2.4rem', margin: '0', display: 'flex' }}>
						{isLoading && !orderData ? (
							<span className={skeletonStyles['skeleton-loader-length']} />
						) : (
							orderData?.pagination.count || 0
						)}{' '}
						{orderData?.orders.length !== 1 ? translations.ordersPlaced : translations.orderPlaced}
					</Heading>
					<Select
						buttonProps={{ size: isDesktopView ? 'large' : 'small' }}
						handler={handleSelect}
						values={getOrderFilterOptions(locale)}
						placeholder={getOrderFilterPlaceHolder(locale)}
					/>
				</Flex>
				{isBfroDiscount && <DiscountBanner />}
				{historyList}
			</Flex>
			<Pagination
				handlePagination={handlePagination}
				isLoading={isLoading}
				pagination={orderData?.pagination}
				pagesLength={pagesLength}
			/>
		</>
	);
};

export default HistoryList;
